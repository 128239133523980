import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Form, InputNumber, Modal, Radio } from 'antd';
import styles from './saleInfo/index.less';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import moment from 'moment';

const { Item } = Form;
const { Group } = Radio;

const dateFormatList = ['YYYY-MM-DD HH:mm:ss'];

export const DeliveryWayModal: React.FC<{ store; }> = observer((props) => {
  const {
    showDeliveryModal,
    closeDeliveryModal,
    currentSku,
    updateDeliveryWay,
  } = props.store;
  const [
    stockType,
    setStockType,
  ] = useState(0);

  const [
    fullPaymentPresaleDeliveryType,
    setFullPaymentPresaleDeliveryType,
  ] = useState(0);

  const [form] = useForm();

  useEffect(() => {
    if (!showDeliveryModal) {
      setStockType(0);
      setFullPaymentPresaleDeliveryType(0);
      form.resetFields();
    }
  }, [showDeliveryModal]);

  useEffect(() => {
    if (!currentSku) {
      form.setFieldsValue({ stockType: 0 });
      return;
    }
    setStockType(currentSku.stockType);
    setFullPaymentPresaleDeliveryType(currentSku.fullPaymentPresaleDeliveryType);
    form.setFieldsValue({
      stockType: currentSku.stockType || 0,
      fullPaymentPresaleDeliveryType: currentSku.fullPaymentPresaleDeliveryType || 0,
      preSaleTime: [
        dayjs(currentSku.presaleBeginTime || moment().format(dateFormatList[0]), dateFormatList),
        dayjs(currentSku.presaleEndTime || moment().format(dateFormatList[0]), dateFormatList),
      ],
      fullPaymentPresaleDeliveryTime: currentSku.fullPaymentPresaleDeliveryTime,
    });
  }, [
    currentSku,
    form,
  ]);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      onCancel={closeDeliveryModal}
      onOk={() => {
        const values = form.getFieldsValue();
        if (values.preSaleTime) {
          values.presaleBeginTime = values.preSaleTime[0].format(dateFormatList[0]);
          values.presaleEndTime = values.preSaleTime[1].format(dateFormatList[0]);
          delete values.preSaleTime;
        }
        console.log(values);
        updateDeliveryWay(values);
        closeDeliveryModal();
      }}
      open={showDeliveryModal}
      title="发货方式"
      width={460}
    >
      {showDeliveryModal && (
        <Form
          form={form}
          initialValues={{
            stockType: 0,
            fullPaymentPresaleDeliveryType: 0,
          }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <Item
            className={styles.mb0}
            extra={stockType === 0 ? '买家需要全款支付商品，商家需要在买家支持后3日内完成发货' : '买家需要全款支付商品，商家需要在买家支持后4-15内完成发货'}
            label="库存情况"
            name="stockType"
          >
            <Group onChange={(e) => {
              setStockType(e.target.value);
            }}
            >
              <Radio value={0}>
                现货
              </Radio>
              <Radio value={1}>
                全款预售
              </Radio>
            </Group>
          </Item>
          {stockType === 1 && (
            <>
       
              <Item
                className={styles.mb0}
                extra={fullPaymentPresaleDeliveryType === 0 ? '需要在买家支付后4-15天内完成发货' : '设置预售周期，预售周期结束后开始计算发货时间'}
                label="发货节点"
                name="fullPaymentPresaleDeliveryType"
              >
                <Group onChange={(e) => {
                  setFullPaymentPresaleDeliveryType(e.target.value);
                }}
                >
                  <Radio value={0}>
                    买家付款后n天发货
                  </Radio>
                  <Radio value={1}>
                    商家预售结束后n天发货
                  </Radio>
                </Group>
              </Item>

              {fullPaymentPresaleDeliveryType === 1 && (
                <Item
                  className={styles.mb0}
                  extra="预售周期不超过15天，预售周期结束后将下架商品"
                  label="预售周期"
                  name="preSaleTime"
                >
                  <DatePicker.RangePicker
                    format={dateFormatList[0]}
                    showTime
                  />
                </Item>
              )}
   
              <Item
                className={styles.mb0}
                extra="可输入天数为4-15天的整数"
                label="发货时效"
                name="fullPaymentPresaleDeliveryTime"
              >
                <DeliveryInput
                  max={15}
                  min={4}
                />
              </Item>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
});

const DeliveryInput = (props) => {
  const { onChange, max, min, value } = props;
  return (
    <div className={styles.gridInputWrapper}>
      预售结束后
      <InputNumber
        max={max}
        min={min}
        onChange={onChange}
        style={{
          marginLeft: 4,
          marginRight: 4,
        }}
        value={value}
      />
      天发货
    </div>
  );
};
