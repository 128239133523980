import { Affix, Button, Col, Modal, Row, Spin, Steps } from 'antd';
import React from 'react';
import styles from './index.less';

export const FooterButtons: React.FC<{ canNext: boolean;onNext; }> = (props) => {
  const { canNext, onNext } = props;
  return (
    <Affix offsetBottom={0}>
      <div className={styles.footerWrap}>
        <Row>
          <Col
            className={styles.Wrap}
            span={4}
          />
          <Col
            className={styles.footer}
            span={20}
          >
            <Button
              disabled={!canNext}
              onClick={onNext}
              type="primary"
            >
              下一步
            </Button>
          </Col>
        </Row>
      </div>
    </Affix>
  );
};
