// import type { FormInstance } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, flow, toJS } from 'mobx';
import { nanoid } from 'nanoid';
import type { FormInstance } from 'antd';
import { message } from 'antd';
import React from 'react';
import { api } from '../../../utils';
import { scrollToAnchor } from '../../../utils/tools';
import { MainImgsStore, ProductInfoStore, sliceStrLen, dealOversizeImg, charaRegGlobal, getImageInfo, dealUnlegalImg, dealGoodsProps } from '../../shelvesBase';
import type { IStyleImgRes } from '../interface';
import { AnchorStore } from './anchorStore';
import { SizeColorStore } from './priceStock/saleInfo/sizeColorStore';
import { UploadIVStore } from './uploadStore';
import type { Brand } from '../types';

export class StepTwoStore {
  constructor(parent) {
    this.parent = parent;
  }

  public parent;

  public mainImgsStore = new MainImgsStore();

  public singleUploadImgStore = new UploadIVStore(this);

  public sizeColorStore = new SizeColorStore(this);

  public productInfoStore = new ProductInfoStore(this);

  public anchorStore = new AnchorStore(this);

  @observable public expressTemplateOptions = []; // 运费模版

  @observable public resultVisilbe = false; // 结果弹窗是否展示

  @observable public goodsProps = [];

  @observable public shopName = ''; // 店铺名称

  @observable public productNameHasFilled = 0;

  public formRef = React.createRef<FormInstance>();

  @observable public submitResult = ''; // 提交信息

  @observable public errorMessage = ''; // 错误信息

  @observable public goodsId = ''; // 上架到平台的商品id

  @observable public brandOptions = []; // 品牌选项

  @observable public listingMode = 0;

  public getBrandList = () => {
    return request<BaseData<Brand[]>>({
      url: '/api/gms/goods/platform/weixin/brandList',
      method: 'POST',
      data: { shopId: this.parent.shopId },
    });
  };

  public closeTab = () => {
    window.top.egenie.closeTab(this.parent.pageId);
  };

  @action public closeResultModal = () => {
    this.resultVisilbe = false;
  };

  // 填充第二步表单内容
  public initFormData = async(data) => {
    const { goodsBase, platformExtends, goodsProps, goodsPic, saleProps, goodsPropPicList, goodsSkuList } = data;

    const { goodsName, goodsNo } = goodsBase;
    const {
      subTitle,
      details,
      expressTemplateId,
      brandId,
      aftersaleDesc,
      limitedBuyNum = 0,
      periodType = 0,
      listing,
    } = platformExtends;

    // 初始化商品标题字数
    const productName = goodsName.replace(charaRegGlobal, 'rr');
    this.productNameHasFilled = productName.length;

    try {
      // 类目为品牌 选项分页获取
      const result = await this.getBrandList();
      this.brandOptions = this.parent.mapOptions(result.data, 'brandId', 'brandName') || [];
    } catch (e) {
      console.log('获取品牌出错', e);
    }

    // 年份季节倒序排列
    let requiredProps = 0;

    goodsProps.forEach((item) => {
      if (item.required) {
        requiredProps++;
      }

      // 补丁代码:类目为上市季节年份 按照最新年份倒序排列
      if (item.id === '272') {
        item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
      }
    });

    // 商品属性排序(必填项放在最前面)
    this.goodsProps = dealGoodsProps(goodsProps);

    // 必填信息数
    this.anchorStore.allTitles.baseinfo.requiredNum = requiredProps + 1;

    // 取前9张
    const mainPics = goodsPic.mainPics.slice(0, 15);

    // 处理主图
    try {
      for (let i = 0; i < mainPics.length; i++) {
        goodsPic.mainPics[i] = await this.dealMainImg(goodsPic.mainPics[i]);
      }

      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPic);
    } catch (e) {
      console.log('处理主图出错', e);
    }

    // 取前20张
    const detailPics = goodsPic.detailPics.slice(0, 20);

    // 处理商品详情图
    try {
      for (let i = 0; i < detailPics.length; i++) {
        goodsPic.detailPics[i] = await this.dealMainImg(goodsPic.detailPics[i]);
      }
      this.productInfoStore.setDetails(details);
      this.productInfoStore.writeBackDetailImgs(goodsPic); // 回写商品详情
    } catch (e) {
      console.log('处理详情图出错', e);
    }

    // 初始化颜色尺码
    this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList);

    // 划线价
    let initSalePrice;
    if (goodsSkuList?.length > 0) {
      initSalePrice = Math.max(...goodsSkuList.map((item) => item.salePrice)) + 1;
    } else {
      initSalePrice = 1;
    }

    // 回写表单内容
    this.formRef?.current.setFieldsValue({
      goodsName,
      brandId,
      goodsNo,
      subTitle,
      details,
      salePrice: initSalePrice,
      expressTemplateId: expressTemplateId ?? this.expressTemplateOptions[0]?.value,
      aftersaleDesc,
      limited: {
        limitedBuyNum,
        periodType: periodType || 0,
      },
      listing,
    });
  };

  // 组装提交信息
  private releaseData = () => {
    // 收集信息
    const data = this.formRef.current?.getFieldsValue();
    const {
      goodsName,
      subTitle,
      expressTemplateId,
      goodsNo,
      limited = {},
      aftersaleDesc,
      listing,
      brandId,
    } = data;
    const goodsBase = {
      goodsName,
      goodsCategoryId: this.parent.categoryId,
      goodsCategoryIds: this.parent.categoryIds,
      goodsCategoryFullName: this.parent.categoryName.replace(/>/g, ','),
      goodsNo,
    };

    // 图片信息
    const goodsPicVo = {
      mainPics: this.mainImgsStore.mainPics.map((item) => item.url),
      detailPics: this.productInfoStore.imgs.length > 0 ? this.productInfoStore.imgs.map((item) => item.url) : [],
    };

    const goodsSkuSetOnShelfRequestList = toJS(this.sizeColorStore.goodsSkuList);
    const checkedColorList = (this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${item.name}`,
      smallPicUrl: '',
    }));

    // 商品属性
    let goodsProps = this.goodsProps.map((cur) => {
      const { id, type, name, checkOptions } = cur;
      const values = data;
      let eachValue;
      let eachValueName;

      if (type !== 'INPUT') {
        eachValue = typeof values[id] === 'object' ? values[id] && values[id].join(',') : values[id];
        if (typeof values[id] === 'object') {
          const eachValueNames = checkOptions.filter((item) => values[id].includes(item.key));
          eachValueName = eachValueNames.map((item) => item.value)?.join(',');
        } else {
          eachValueName = checkOptions.find((item) => item.key === eachValue)?.value;
        }
      } else {
        eachValue = values[id];
        eachValueName = values[id];
      }

      return {
        id,
        name,
        type,
        value: eachValue,
        valueName: eachValueName,
      };
    });

    // 过滤掉没有值的商品属性
    goodsProps = goodsProps.filter((item) => {
      return item.value;
    });

    const brandOptions = this.brandOptions.find((e) => e.value === brandId);
    const brand = {
      brandId: brandId || undefined,
      brandName: brandOptions ? brandOptions.label : undefined,
    };
    const platformExtends = {
      subTitle,
      details: this.productInfoStore.details,
      expressTemplateId,
      goodsProps,
      aftersaleDesc,
      listing,
      ...brand,
      ...limited,
    };

    const submitData = {
      gmsGoodsId: this.parent.gmsGoodsId,
      goodsBase,
      platformExtends,
      goodsPicVo,
      goodsSkuSetOnShelfRequestList,
      goodsPropPicVos,
    };

    return submitData;
  };

  // 获取主图款式库图片
  public getPosGoodsGroupPics = (): void => {
    request<BaseData<IStyleImgRes[]>>({
      url: api.getPosGoodsGroupPics,
      method: 'POST',
      data: { gmsGoodsId: this.parent.gmsGoodsId },
    })
      .then(async(res) => {
        const styleList = res.data[0].mainPicUrls;
        for (let i = 0; i < styleList.length; i++) {
          try {
            const url = await this.dealMainImg(styleList[i]);
            this.mainImgsStore.styleImgLists.push({
              id: nanoid(),
              imgId: nanoid(),
              url,
            });
          } catch (e) {
            console.log('获取主图款式库图片出错', e);
          }
        }
      })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  // 处理主图
  private dealMainImg = async(url) => {
    const { Format, FileSize } = await getImageInfo(url);

    let newUrl = dealUnlegalImg(url, Format.value);

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 2) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }
    return newUrl;
  };

  @action public saveInfo = () => {
    this.anchorStore.isValid = 'init';

    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }

    this.parent.loading = true;
    const data = this.releaseData();
    console.log(data);

    request<BaseData>({
      url: '/api/gms/goods/platform/weixin/saveGoodsPutOnShelf',
      method: 'POST',
      data,
    })
      .then((res) => {
        message.success(res.data || '保存成功');
      })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 提交上架信息
  public submitInfo = flow(function* () {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    yield this.anchorStore.getIncomplete();

    const allTitles = this.anchorStore.allTitles;
    const item = Object.keys(allTitles);
    for (let i = 0; i < Object.keys(allTitles).length; i++) {
      if (allTitles[item[i]].hasFilled < allTitles[item[i]].requiredNum) {
        scrollToAnchor(allTitles[item[i]].id);
        return;
      }
    }

    const data = this.releaseData();

    this.parent.loading = true;
    this.submitResult = 'inProgress';
    this.resultVisilbe = true;
    request<BaseData<any>>({
      url: '/api/gms/goods/platform/weixin/goodsPutonShelf',
      method: 'POST',
      data,
    })
      .then((res) => {
        const { shopName, upStatus, reason, platformId } = res.data;
        this.shopName = shopName;
        this.errorMessage = reason;
        this.goodsId = platformId;
        this.submitResult = upStatus ? 'Successful' : 'Failed';
        this.listingMode = data.platformExtends.listing === 0 ? 1 : 0;
      })
      .catch((e) => {
        this.submitResult = 'Failed';
        this.errorMessage = e.data?.info || e.data?.message;
      })
      .finally(() => {
        this.parent.loading = false;
      });
  });
}
