import { observer } from 'mobx-react';
import React from 'react';
import { CategorySelector, FooterButtons } from '../../shelvesBase';
import styles from './index.less';

export const StepOne = observer((props: { stepOneStore; }) => {
  const { onNext, categoryStore } = props.stepOneStore;
  const { canNext } = categoryStore;
  return (
    <div
      className={styles.stepOneWrap}
    >
      <CategorySelector store={categoryStore}/>
      <FooterButtons
        canNext={canNext}
        onNext={onNext}
      />
    </div>
  );
});

