import { Col, Form, Input, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { calcStrLen, ContentWrap } from '../../shelvesBase';
import { FORM_LAY_OUT } from '../constant';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;
const { Option } = Select;

export const BaseInfo = observer(({ store }: { store: StepTwoStore; }) => {
  const [
    title,
    setTitle,
  ] = useState('');
  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 30) {
      return;
    }
    setTitle(e.target.value);
  };

  return (
    <ContentWrap
      id="baseInfo"
      text="基本信息"
    >
      <Item
        label="商品标题"
        name="goodsName"
        rules={[
          {
            required: true,
            message: '请输入商品标题',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || (value && calcStrLen(value) < 61)) {
                return Promise.resolve();
              }
              return Promise.reject('商品标题最多输入30个字符');
            },
          }),
        ]}
      >
        <Input
          maxLength={30}
          onChange={onChangeTitle}
          placeholder="商品标题组成：商品描述+规格 最多输入30个汉字"
          suffix={`${title.length}/30`}
          value={title}
        />
      </Item>
      <Item
        label="商品描述"
        name="subTitle"
      >
        <Input/>
      </Item>
      <Row>
        <Col span={12}>
          <Item
            label="商品品牌"
            labelCol={{ span: 6 }}
            name="brandId"
            wrapperCol={{ span: 18 }}
          >
            <Select options={store.brandOptions}/>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="商品编码"
            labelCol={{ span: 6 }}
            name="goodsNo"
            wrapperCol={{ span: 18 }}
          >
            <Input/>
          </Item>
        </Col>
      </Row>

      <ProductProp store={store}/>
    </ContentWrap>
  );
});

// 商品属性
const ProductProp: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  // @ts-ignore
  const { goodsProps, formItemOnChange, brandOptions } = props.store;
  return (
    <Item label="商品参数">
      <div className={styles.itemWrapper}>
        {goodsProps &&
          goodsProps.map((item, index) => {
            // ... 在此处不同布局的判断
            const key = item.id;
            return (
              <Col
                className={item.className || ''}
                key={key}
                span={item.colspan || 12}
              >
                <Item
                  {...(item.itemLayout || FORM_LAY_OUT)}
                  initialValue={item.type === 'INPUT' ? item.value : item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : item.value || undefined}
                  label={item.name}
                  name={key}
                  normalize={(value, prevValue, allValues) => {
                    if (item.type === 'MULTICHECK') {
                      if (typeof value === 'string') {
                        return value.split(',');
                      }
                    }
                    return value;
                  }}
                  rules={[
                    item.rules || {
                      required: item.required,
                      message: item.message,
                    },
                  ]}
                >
                  {item.type === 'INPUT' ? (
                    <Input
                      autoComplete="off"
                      className={item.className || ''}
                      id={key}
                      placeholder={item.placeholder || '请输入'}
                    />
                  ) // 品牌的话分页选项返回
                    : (
                      <Select
                        allowClear={item.type !== 'INPUT'}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                        notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                        onChange={(val) => {
                          formItemOnChange(key, val, index);
                        }}
                        placeholder="请选择"
                        showSearch
                      >
                        {item.checkOptions &&
                        item.checkOptions.map((v) => {
                          return (
                            <Option
                              key={v.key}
                              value={v.key}
                            >
                              {v.value}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                </Item>
              </Col>
            );
          })}
      </div>
    </Item>
  );
});
