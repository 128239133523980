import { message } from 'antd';
import { EgGridModel } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, toJS, runInAction } from 'mobx';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { scrollToAnchor } from '../../../../../utils';
import { SkuBaseStore } from '../../../../shelvesBase';
import type { StepTwoStore } from '../../store';
import { saleSpecColumns } from './skuColumns';

export class SizeColorStore extends SkuBaseStore {
  public handleSalePriceAddProfit: (profit: number, multiAdd?: number) => void;

  constructor(parent) {
    super();
    this.parent = parent;
  }

  @observable public parent: StepTwoStore;

  @observable public salePrice: number;// 单买价

  @observable public allNum: number;// 批量数量

  @observable public showDeliveryModal = false;// 是否展示发货方式弹窗

  @observable public currentSku;

  @action public openDeliveryModal = () => {
    this.showDeliveryModal = true;
  };

  @action public closeDeliveryModal = () => {
    this.showDeliveryModal = false;
    this.currentSku = null;
  };

  @action
  public setCurrentSku = (sku) => {
    this.currentSku = sku;
  };

  @action
  public updateDeliveryWay = (values) => {
    if (this.currentSku) {
      const row = this.goodsSkuList.find((item) => item.goodsSpec === this.currentSku.goodsSpec);
      Object.assign(row, values);
      this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
      return;
    }
    this.goodsSkuList = this.goodsSkuList.map((e) => {
      return {
        ...e,
        ...values,
      };
    });
    this.productSaleSpecGridModel.rows = this.goodsSkuList;
  };

  // 商品规格表
  public productSaleSpecGridModel = new EgGridModel({
    columns: saleSpecColumns(this),
    rows: [],
    showPagination: false,
    showSelectedTotal: false,
    showCheckBox: false,
    showRefresh: false,
    showPager: false,
    primaryKeyField: 'goodsSpec',
  });

  // 删除颜色图片
  @action public deleteColorImg = (id: number | string) => {
    const colorIndex = this.colorList.findIndex((item) => item.id === id);
    this.colorList[colorIndex].url = '';
  };

  // 映射sku列表为商品规格表格的行
  @action public mapSpecRows = (arr) => {
    return arr.map((item) => {
      const { goodsSkuId, goodsSpec, goodsSkuNo, salePrice, stockNum, multiPrice, skuPreSaleTime, picUrl, costPrice } = item;
      const goodsSpecTemp = goodsSpec.split(';');

      const colorAndSize = {
        color: goodsSpecTemp[0].split(':')[1],
        size: goodsSpecTemp[1].split(':')[1],
      };

      return {
        ...item,
        goodsSpec,
        goodsSkuId,
        goodsSkuNo,
        skuPreSaleTime: skuPreSaleTime ? moment(skuPreSaleTime) : '',
        picUrl,
        costPrice,
        salePrice,
        multiPrice,
        stockNum,
        ...colorAndSize,
      };
    });
  };

  // 生成skulist
  @action public generateSkuList = () => {
    const checkedColorList = this.colorList.filter((item) => item.checked);
    const checkedSizeList = this.sizeList.filter((item) => item.checked);

    // 二重循环生成sku list
    const newList = [];
    checkedColorList.forEach((colorItem) => {
      checkedSizeList.forEach((sizeItem) => {
        const colorName = colorItem.name;
        const { name } = sizeItem;
        const goodsSpec = `${this.colorPropName}:${colorName};${this.sizePropName}:${name}`;// spec
        const eachSku = {
          goodsSpec,
          stockNum: null,
          salePrice: null,
          multiPrice: null,
          goodsSkuNo: '',
          picUrl: '',
        };
        newList.push(eachSku);
      });
    });
    
    // 只是编辑颜色或尺码
    if (this.goodsSkuList.length === newList.length) {
      return;
    }

    let diffList;

    // 判断减少还是增加sku
    if (this.goodsSkuList.length > newList.length) {
      if (!newList.length) {
        diffList = this.goodsSkuList;
      } else {
        diffList = _.differenceBy(this.goodsSkuList, newList, 'goodsSpec');
      }
    
      // 减少sku的话如果减少的sku中含有skuId是初使状态下就有的sku 要保存起来 防止又重新勾选
      const saveList = diffList.filter((item) => item.goodsSkuId);
      this.tempSaveSkuList.push(...saveList);
    
      this.goodsSkuList = _.intersectionBy(this.goodsSkuList, newList, 'goodsSpec');
    } else {
      if (!this.goodsSkuList.length) {
        diffList = newList;
      } else {
        diffList = _.differenceBy(newList, this.goodsSkuList, 'goodsSpec');
      }
          
      // 新增sku
      // 检查新增sku是否是初始状态下就有的
      const initList = _.intersectionBy(this.tempSaveSkuList, diffList, 'goodsSpec');
    
      this.goodsSkuList.push(...initList);
      const restList = _.differenceBy(diffList, initList, 'goodsSpec');
    
      this.goodsSkuList.push(...restList);
    }
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 修改单个sku售价
  @action public handleSingleSalePriceChange = (spec, price) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].salePrice = price;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 修改所有sku售价(Change)
  @action public handleAllSalePriceChange = (price) => {
    this.salePrice = price;
  };

  // blur
  @action public handleAllSalePriceBlur = () => {
    if (!this.salePrice) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.salePrice = this.salePrice;
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.salePrice = null;
  };

  // 修改sku Blur
  @action public handleSkuChange = (spec, val) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].goodsSkuNo = val;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  @action public handleAllNumChange = (num) => {
    this.allNum = num;
  };

  @action public handleAllNumBlur = () => {
    if (!this.allNum) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.stockNum = this.allNum;
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);

    this.allNum = null;
  };

  // 修改单个sku数量
  @action public handleSingleNumChange = (spec, num) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].stockNum = num;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };
  
  // 初始化颜色数量
  @action public initData = (saleProps: any[], goodsPropPicList: any[], goodsSkuList: any[]) => {
    // 尺码列表
    const sizeObj = saleProps.find((item) => item.propsName.includes('尺码') || item.propsName.includes('尺寸') || item.propsName.includes('身高'));
    this.sizeList = sizeObj?.values.map((item) => ({
      key: nanoid(5),
      name: item.name,
      checked: true,
      editStatus: false,
    }));

    this.sizePropName = sizeObj?.propsName;

    // 颜色列表
    const colorObj = saleProps.find((item) => item.propsName.includes('颜色'));
    this.colorPropName = colorObj?.propsName;
    this.colorList = colorObj?.values.map((item) => {
      const picItem = goodsPropPicList.find((eachPic) => eachPic.properties === `${this.colorPropName}:${item.name}`);
      return {
        key: nanoid(5),
        name: item.name,
        checked: true,
        editStatus: false,
        url: picItem?.url || '',
      };
    });

    // 处理sku列表
    // 如果skuList不为数组，直接置空
    if (!(Array.isArray(goodsSkuList))) {
      this.productSaleSpecGridModel.rows = [];
    }

    this.goodsSkuList = goodsSkuList;

    this.goodsSkuList.forEach((item) => {
      item.stockNum = item.stockNum || 100;
    });
          
    // 填入表格行
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);

    // 防止初始状态下修改价格和数量报错
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
  };

  // 保存提交校验
  @action public saveValidate = () => {
    // 校验颜色图片
    const checkedColorList = this.colorList.filter((item) => item.checked);
    if (checkedColorList.some((item) => !item.url)) {
      message.warn('请为选中颜色上传图片');
      this.toggleExpandColor(true);
      return false;
    }

    // 保存的话只校验销售信息
    if (!this.goodsSkuList.length) {
      message.warn('请填写商品规格');
      return false;
    }

    // 校验商品规格信息
    if (this.goodsSkuList.some((item) => {
      return !item.salePrice || !item.stockNum;
    })) {
      message.warn('请完善商品规格信息');
      return false;
    }

    return true;
  };
}
