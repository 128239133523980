import { message } from 'antd';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { UploadStore } from '../../../utils/upLoad/uploadStore';

export class UploadIVStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public type = '';

  @observable public code = '';

  public mainPicTip = '图片大小不超过10M最多上传8张';

  @observable public videoFileList = [];

  @action public callBack = (params) => {
    const { mainPics } = this.parent.mainImgsStore;

    const { imgs } = this.parent.productInfoStore;
    if (this.type === 'color') {
      const colorIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === this.code);
      this.parent.sizeColorStore.colorList[colorIndex].url = params[0].url;
    } else if (this.type === 'mainPic') {
      const uploadImgs = this.handleImgs(params);
      this.parent.mainImgsStore.mainPics = [
        ...mainPics,
        ...(uploadImgs),
      ];
    } else if (this.type === 'productInfoImg') {
      const uploadImgs = this.handleImgs(params);
      this.parent.productInfoStore.imgs = [
        ...imgs,
        ...uploadImgs,
      ];
    } else if (this.type === 'videoManage') {
      const { id, url } = params[0];
      this.videoFileList = [
        {
          id,
          url,
        },
      ];
    }
    this.type = '';
  };

  @action public handleImgs = (imgs) => {
    return imgs.map((item) => {
      item.imgId = nanoid();
      return item;
    });
  };

  @observable public uploadStoreImg = new UploadStore({
    type: 'picManage', // picManage 图片 videoManage 视频
    multiple: false, // 是否允许多选文件
    limitNumber: 1, // 限制只能上传一个文件或者说只能选择一个文件
    callBack: this.callBack,
  });

  @action public selectImg = (type, code?, imgSize?): void => {
    let limitNumber = 1;

    let limitSize;

    // 主图最多传9张
    if (type === 'mainPic') {
      limitNumber = (9 - this.parent.mainImgsStore.mainPics.length);
      if (limitNumber < 1) {
        message.error('最多上传9张主图,请删除后再上传');
        return;
      }
      limitSize = 2;
    }

    // 详情图最多传9张
    if (type === 'productInfoImg') {
      limitNumber = (20 - this.parent.productInfoStore.imgs.length);
      if (limitNumber < 1) {
        message.error('最多上传20张详情图,请删除后再上传');
        return;
      }
      limitSize = 2;
    }

    if (type === 'whitePics' || type === 'materialPics') {
      imgSize = {
        width: 800,
        height: 800,
      };
    }
    const multiple = Boolean(type === 'mainPic') || Boolean(type === 'productInfoImg');// 主图和详情允许多选
    this.type = type;
    this.code = code;
    this.uploadStoreImg = new UploadStore({
      type: type === 'videoManage' ? 'videoManage' : 'picManage', // picManage 图片 videoManage 视频
      // activeTab: 'manage',
      imgSize,
      multiple, // 是否允许多选文件
      limitNumber, // 限制只能上传一个文件或者说只能选择一个文件
      callBack: this.callBack,
    });

    this.uploadStoreImg.onOpen();
  };

  // 删除颜色图片
  @action public deleteImg = (type, key?) => {
    if (type === 'color') {
      // 颜色
      const deleteIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === key);
      this.parent.sizeColorStore.colorList[deleteIndex].url = '';
    }
  };
}
