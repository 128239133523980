import _ from 'lodash';
import { action, observable, toJS } from 'mobx';

export class AnchorStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public isValid = 'init';

  @observable public allTitles = {
    baseinfo: {
      title: '基本信息',
      requiredNum: 1,
      hasFilled: 0,
      id: 'baseInfo',
    },
    imgTextInfo: {
      title: '图文信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'imgTextInfo',
    },
    priceStock: {
      title: '价格库存',
      requiredNum: 4,
      hasFilled: 3,
      id: 'priceStock',
    },
    moreSetting: {
      title: '更多设置',
      requiredNum: 0,
      hasFilled: 0,
      id: 'moreSetting',
    },

  };

  @observable public currentLink = '';

  @action public changeAnchor = (currentLink) => {
    this.currentLink = currentLink;
  };

  @action public getIncomplete = async() => {
    const allTitles = this.allTitles;
    this.isValid = 'valid';

    try {
      await this.parent.formRef.current?.validateFields();
    } catch (e) {
      console.log(e);
    } finally {
      const data = await this.parent.formRef.current?.getFieldsValue();
      
      // 基本信息
      allTitles.baseinfo.hasFilled = 0;
      if (data.goodsName) {
        allTitles.baseinfo.hasFilled++;
      }

      // 商品属性
      this.parent.goodsProps.forEach((item) => {
        if (item.required && (data[item.id])) {
          allTitles.baseinfo.hasFilled++;
        }
      });

      // 图文信息
      allTitles.imgTextInfo.hasFilled = 0;
      if (this.parent.mainImgsStore.mainPics.length > 0) {
        allTitles.imgTextInfo.hasFilled++;
      }

      if (this.parent.productInfoStore.imgs.length > 0) {
        allTitles.imgTextInfo.hasFilled++;
      }

      // 价格库存
      allTitles.priceStock.hasFilled = 3;
      
      const list = this.parent.sizeColorStore.productSaleSpecGridModel.rows.filter((e) => (!_.isNumber(e.salePrice) || e.salePrice <= 0));

      if (list.length <= 0) {
        allTitles.priceStock.hasFilled++;
      }
    }
  };
}
