import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { StepsComponent } from '../shelvesBase';
import styles from './index.less';
import { StepOne } from './stepOne';
import { StepTwo } from './stepTwo';
import { ShelvesKsStore } from './store';

const store = new ShelvesKsStore();
@observer
class ShelvesNew extends React.Component {
  render() {
    const { loading, currentStep, stepTwoStore } = store;
    return (
      <div
        className={styles.contentWrap}
        id="fullModalWrap"
      >
        <Spin spinning={loading}>
          <StepsComponent currentStep={currentStep}/>
          <div
            className={styles.stepContent}
          >
            {currentStep < 1 ? <StepOne stepOneStore={store}/> : <StepTwo stepTwoStore={stepTwoStore}/>}
          </div>
        </Spin>
      </div>
    );
  }
}

export default ShelvesNew;
