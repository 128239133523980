import { Form, Input, Select, Switch, Radio, Row, InputNumber } from 'antd';
import React, { useState } from 'react';
import { ContentWrap } from '../../shelvesBase';
import type { StepTwoStore } from './store';
import styles from './index.less';

const { Item } = Form;

export const MoreSetting: React.FC<{ store: StepTwoStore; }> = (props) => {
  const { store } = props;
  return (
    <ContentWrap
      id="moreSetting"
      text="更多设置"
    >
      <Item
        label="运费模版"
        name="expressTemplateId"
        rules={[{ required: true }]}
      >
        <Select
          options={store.expressTemplateOptions}
          style={{ width: 469 }}
        />
      </Item>
      <Item
        label="售后说明"
        name="aftersaleDesc"
      >
        <Input.TextArea
          maxLength={200}
          placeholder="填写说明，限200字"
          rows={3}
          showCount
        />
      </Item>
      <Item
        label="购买限制"
        name="limited"
      >
        <Limited/>
      </Item>
      <Item
        label="上架时间"
        name="listing"
        rules={[{ required: true }]}
      >
        <Radio.Group defaultValue={1}>
          <Radio value={1}>
            立即上架
          </Radio>
          <Radio value={0}>
            放入草稿
          </Radio>
        </Radio.Group>
      </Item>
    </ContentWrap>
  );
};

const LimitedOptions = [
  {
    label: '每日',
    value: 1,
  },
  {
    label: '每周',
    value: 2,
  },
  {
    label: '每月',
    value: 3,
  },
];

export interface LimitedProps{
  onChange?: (data: { periodType: number;
    limitedBuyNum: number; }) => void;
  value?: {
    periodType: number;
    limitedBuyNum: number;
  };
}

const Limited = (props: LimitedProps) => {
  console.log(props);
  const { onChange, value } = props;
  const { periodType = 0, limitedBuyNum = 1 } = value || {};
  
  return (
    <>
      <Row align="middle">
        <Switch
          checked={periodType !== 0}
          onChange={(checked) => {
            onChange({
              periodType: checked ? 1 : 0,
              limitedBuyNum: checked ? 1 : 0,
            });
          }}
          style={{ marginRight: 4 }}
        />
        开启限购
      </Row>
  
      {periodType !== 0 && (
        <div>
          <Row className={styles.purchaseLimit}>
            <span>
              每人:
            </span>
            <Select
              onChange={(e) => {
                onChange({
                  periodType: e,
                  limitedBuyNum,
                });
              }}
              options={LimitedOptions}
              value={periodType}
            />
            <span>
              限购
            </span>
            <InputNumber
              onChange={(e) => {
                onChange({
                  periodType,
                  limitedBuyNum: Number(e),
                });
              }}
              value={limitedBuyNum}
            />
            <span>
              件
            </span>
          </Row>
          <div className={styles.purchaseLimitHint}>
            {LimitedOptions.find((e) => e.value === periodType)?.label}
            00:00刷新限购资格
          </div>
        </div>
      ) }
    </>
  );
};
